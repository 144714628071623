.content {
  margin: 20px;
}

.organizationSelectorContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.selectorOption {
  width: 100%;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.optionIcon {
  margin-inline-end: 10px;
}

.divider {
  height: 100px;
  border: 1px solid var(--color-content-4);
}
