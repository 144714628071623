.largeScreenSideMenu {
  height: 100%;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.12);
}

.appName {
  margin: 10px 0;
}

.appNameButton {
  width: 100%;
  text-align: center;
  color: var(--color-white);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.appVersion {
  color: var(--color-white);
  margin: 10px;
  text-align: center;
  font-size: 12px;
}

.list {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
