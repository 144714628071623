.paper {
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.paperContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
