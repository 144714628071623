.conatctIconField {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.pointer {
  cursor: pointer;
}

.fieldValue {
  margin-inline-start: 10px;
}
