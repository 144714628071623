.appNameButton {
  width: 100%;
  text-align: center;
  color: var(--color-white);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.backIcon {
  color: var(--color-white);
}
