.screen {
  display: flex;
  flex-direction: row;
}

.padding {
  padding: 20px;
}

.screenContent {
  flex: 1;
}

.sideMenu {
  position: sticky;
  height: 100%;
  top: 0;
  width: 350px;
}

.sideMenuPaper {
  margin: 20px 20px;
  padding: 20px;
}
