.field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
  margin-inline-end: 10px;
  width: 100%;
}

.label {
  font-weight: bold;
}

.value {
  margin-inline-start: 5px;
}
