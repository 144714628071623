.cardContainer {
  margin: 10px 0 10px 20px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px 15px 20px 25px;
  cursor: pointer;
  width: 300px;
}

.card:hover {
  background-color: var(--color-hover);
}

.content {
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.icon {
  margin-inline-end: 10px;
}

.orderIcon {
  color: var(--color-order-icon);
}

.organizationIcon {
  color: var(--color-organization-icon);
}

.participantIcon {
  color: var(--color-participant-icon);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.message {
  height: 24px;
}
.error {
  color: var(--color-error);
}
