.text {
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.bold {
  margin-left: 5px;
  color: green;
  font-weight: bold;
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
