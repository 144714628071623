.participant {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
}

.borderTop {
  border-top: 1px solid var(--color-content-5);
  padding: 15px 0;
}

.control {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.participantContent {
  display: flex;
  flex-direction: column;
}

.participantFields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
}

.lectures {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
