.detailsSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lectureDetailsFields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
