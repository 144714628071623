.app {
  position: relative;
  width: 100%;
  height: 100%;
  direction: rtl;
  display: flex;
  overflow: hidden;
}

.appMobileScreen {
  flex-direction: column;
}

.appContent {
  flex: 1;
  overflow-y: auto;
}
