.field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fileName {
  padding: 0 20px;
}

.error {
  padding: 0 20px;
  color: red;
}
