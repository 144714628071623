.loadingScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin: 50px;
}

@media (max-width: 600px) {
  .loadingScreenContainer {
    justify-content: center;
    margin: 0;
  }
}

.iconImage {
  height: 150px;
}

.spinner {
  padding-top: 20px;
  text-align: center;
}
