.container {
  text-align: center;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  padding: 20px;
}

.card {
  width: 400px;
}
