.stepperContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.stepper {
  max-width: 1200px;
  width: 100%;
}
