.title {
  font-size: 24px;
  font-weight: bold;
}

.status {
  font-size: 24px;
}

.times {
  width: inherit;
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 5px;
}

.actionButtons {
  display: grid;
  grid-auto-columns: 1fr 1fr;
}

.actionButtons:first-child {
  grid-column: span 3;
}

.divider {
  margin: 20px 0;
}
