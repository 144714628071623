.instance {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  width: 100%;
}

.indexContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.borderTop {
  border-top: 1px solid var(--color-content-5);
  padding: 15px 0;
}

.evenBackground {
  background-color: var(--color-table-odd-row);
}
