.menuTitle {
  margin: 20px 15px 5px 15px;
  font-size: 15px;
  color: var(--color-white-soft);
}

.listItem {
  margin-inline-end: 10px;
  color: var(--color-white);
}

.selectedListItem {
  background-color: var(--color-primary-light);
}

.icon {
  color: var(--color-white);
}
