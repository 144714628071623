.cell {
  cursor: pointer;
}

.evenBackground {
  background-color: var(--color-table-odd-row);
}

.iconColumn {
  height: 100px;
}

.error {
  background-color: var(--color-error-light);
}
