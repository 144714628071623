.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.searchResultGroup {
  display: flex;
  flex-direction: row;
  min-height: 180px;
  flex-wrap: wrap;
}

.groupTitle {
  font-size: 22px;
  font-weight: bold;
}

.loading {
  width: 100%;
  text-align: center;
  margin: 100px 0;
}
