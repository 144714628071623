.lectureTimeFields {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.videoLink {
  flex: 1;
  width: 70%;
  margin-inline-end: 10px;
}

.lectureTimeActionButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
